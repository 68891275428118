.loader-container{
	display: flex;
	width:100vw;
	height: 100vh;
  flex-direction: column;
	justify-content: center;
	align-items: center;

  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin-bottom: 20px;
  }

  p{
    color: white;
    margin-top: 15px;
    font-family: Arial, sans-serif;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
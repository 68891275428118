body {
  margin: 0;
  overflow: hidden;
  background-color: black;
}

iframe{
  border: none;
}

.container {
  display: flex;
  height: 50vh;
}

.primary-container {
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  animation: 0.5s ease-out 0s 1 dropDown;
  background-size: cover;
  background-image: url("./assets/images/Wallpaper.jpg");

  .editor-container {
    margin: .5rem .75rem;
    padding: .5rem;
    min-width: 120px;
    display:flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;
  }
  
  .editor-container.collapsed {
    flex-grow: 0;
    min-height:100px;
  }

  .editor-toolbar {
    display: flex;
    justify-content: space-between;
    color: white;
    background-color: rgba(38, 38, 38, 0.663);
    padding: .5rem .5rem .5rem 1rem;
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;

    .editor-title{
      width:30%;
      overflow: hidden;
      text-overflow:ellipsis;
    }

    .editor-buttons-container{
      width:70%;
      display: inline;

      .editor-button {
        margin: 0;
        padding: 0 2px;
        background: none;
        border: none;
        cursor: pointer;
        position: relative;

        .editor-icon:active{
          opacity: 0.7;
        }
      }

      .reset-dropdown{
        display: none;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        top:15px;
        left: 0px;
        z-index: 1;
        width:max-content;
        padding: 5px 3px;
        border-radius: 5%;
        font-size: x-small;
        color: white;
        background-color: rgba(75, 75, 75, 0.661);
      }
      
      .reset-dropdown.expanded{
        display: flex;
      }
      
      .reset-dropdown-item{
        padding: 3px 2px;
        border-radius: 7%;
      }
      
      .reset-dropdown-item:hover{
        background-color: rgba(66, 203, 66, 0.801);
      }
      
      .reset-dropdown-item:active{
        background-color: transparent;
      }
    }
  }
}


.iframe-container{
  background-color: white;
  animation: 0.5s ease-out 0s 1 slideInRight backwards;
}

.iframe-container::after{
  background-color: white;
}

.editor-container.collapsed .CodeMirror-scroll {
  position: absolute;
  overflow: hidden !important;
}

/* CodeMirror styling */
.code-mirror-wrapper {
  flex-grow: 1;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  overflow-y: hidden;
}

.CodeMirror {
  height: 100% !important;
}

/* Handle for mobile devices  */
@media only screen and (max-width: 480px){
  body{
    overflow-y: scroll;
  }
  .primary-container {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;

    .editor-container{
      margin: .3rem .5rem;
    }
  }
}

@media only screen and (max-height: 400px){
  body{
    overflow-y: scroll;
  }
}

/* Custom animations */
@keyframes dropDown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 50vh;
  }
}

@keyframes slideInRight {
  0% {
    background-color: black;
    transform: translateX(100%);
  }
  100% {
    background-color: black;
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
